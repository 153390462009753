import React from "react";
import { Helmet } from "react-helmet";
//import PropTypes from "prop-types";
//import { useLocation } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const SEO = ({ title, keywords, description, image, article, author }) => {
    // const { pathname } = useLocation();
    // let
    //     defaultTitle = 'Customer Owned Banking Association | COBA',
    //     titleTemplate = 'COBA | Customer Owned Banking Association',
    //     defaultDescription = 'Customer Owned Banking Association is the industry advocate for Australia’s customer owned banking sector, comprising credit unions, building societies and mutual banks.',
    //     siteUrl = 'https://www.customerownedbanking.asn.au/',
    //     defaultImage = big_logo,
    //     twitterUsername = '@CustomerOwnedBA',
    //     defaultKeywords = 'customer owned banking association, coba, australian mutuals, credit unions, mutual building societies, mutual banks, friendly societies, financial services, australia banking'

    // const seo = {
    //     title: title || defaultTitle,
    //     description: description || defaultDescription,
    //     image: `${siteUrl}${image || defaultImage}`,
    //     url: `${siteUrl}${pathname}`,
    //     keywords: keywords || defaultKeywords
    // }
    return <Helmet title={title}></Helmet>;
};
export default SEO;

// <meta name='keywords' content={seo.keywords} />
// <meta name="description" content={seo.description} />
// <meta name="image" content={seo.image} />
// <meta name="url" content={seo.url} />

// {seo.keywords && <meta property="og:keywords" content={seo.keywords} />}
// {(article ? true : null) && <meta property="og:type" content="article" />}
// {seo.title && <meta property="og:title" content={seo.title} />}
// {seo.description && (
//     <meta property="og:description" content={seo.description} />
// )}
// {seo.image && <meta property="og:image" content={seo.image} />}
// {seo.url && <meta property="og:url" content={seo.url} />}

// <meta name="twitter:card" content="summary_large_image" />
// {twitterUsername && (
//     <meta name="twitter:creator" content={twitterUsername} />
// )}
// {seo.title && <meta name="twitter:title" content={seo.title} />}
// {seo.description && (
//     <meta name="twitter:description" content={seo.description} />
// )}
// {seo.image && <meta name="twitter:image" content={seo.image} />}
// {seo.url && <meta name="twitter:url" content={seo.url} />}
// {seo.keywords && <meta name="twitter:keywords" content={seo.keywords} />}
