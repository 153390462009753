import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const HomePage = () => {
    return (
        <Layout>
            <SEO title="Home" />
            <div id="home-page">
                <h1>This is the starting point of the boilerplate</h1>
            </div>
        </Layout>
    );
};

export default HomePage;
