import React from "react";
import { Route, Switch } from "react-router-dom";
import ErrorPage from "../pages/error";
import HomePage from "../pages/homePage";

export default function AppRoutes() {
    return (
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/404" component={ErrorPage} />
            <Route exact path="*" component={ErrorPage} />
        </Switch>
    );
}
