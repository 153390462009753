import "./bootstrap";
import React from "react";
import ReactDOM from "react-dom";
// import { Provider } from "react-redux";
// import store from "./store";
import App from "./routers";

//setup redux here if needed
ReactDOM.render(<App />, document.getElementById("app"));

// <Provider store={store}>
// </Provider>,
