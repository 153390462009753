import React from "react";

const Header = () => {
    return (
        <section id="main-header" data-testid="main-header">
            <header>HEADER HERE</header>
        </section>
    );
};

export default Header;
