import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollTo = top => {
    const { pathname } = useLocation()
    const scrollToLoc = top ? top : 0

    useEffect(() => {
        window.scrollTo(scrollToLoc, 0)
    }, [pathname])

    return null
}

export default ScrollTo

