import React from "react";
//import ReactGA from "react-ga";

import Header from "./header";
import Footer from "./footer";

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
    // ReactGA.initialize('XXXXXXXX')
    // ReactGA.pageview(window.location.pathname + window.location.search)

    return (
        <div id="main-wrapper" data-testid="layout">
            <Header />
            <main className="page-container" data-testid="page-container">
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
