import React from "react";
import { Link } from "react-router-dom";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ErrorPage() {
    return (
        <Layout>
            <SEO title="404 Error" />
            <div id="error-page">
                <div className="content-wrapper">
                    <h1>Page not found</h1>
                    <h4>404 error</h4>
                    <p>
                        Unfortunately, the page you are looking for could not be
                        found.
                    </p>
                    <Link to="/">Return to home</Link>
                </div>
            </div>
        </Layout>
    );
}

export default ErrorPage;
